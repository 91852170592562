import { createContext, useContext, useState, useEffect } from "react";
import { ReactChildren } from "@/types";
import { useMember } from "@/contexts/member/MemberContext";
import Api from "@/api/Api";
import { EntityID } from "@/types/silverstripe";
import { now } from "@/helpers";
import { DateTime, Interval } from 'luxon';

interface CompanyProviderInterface {
	children: ReactChildren
}

interface CompanyContextData {
	fetchCompany: () => void,
	companyID: EntityID,
	setCompanyID: (entityID: EntityID) => void,
	company: CompanyInterface | null,
	market: MarketInterface | null,
	targetCompanyID: EntityID,
	setTargetCompanyID: (entityID: EntityID) => void,

	useProductImage: boolean,
	saveToggleUseProductImage: (toggle: boolean) => void,

	useTextClub: boolean,
	saveToggleUseTextClub: (toggle: boolean) => void
}

const CompanyContext = createContext<CompanyContextData>(
	{} as CompanyContextData
);

interface MarketInterface {
	id: EntityID,
	storeID: number,
	name: string,
	totalMembers: number,
	monthlyPrints: number
}

export interface CompanyInterface {
	id: EntityID,
	name: string,
	licenseEnabled: boolean,
	licenseExpiration: string,
	licenseExpirationRemainingLabel: string | null,
	isLicenseValid: boolean,
	totalMarkets: number,
	totalMembers: number,
	markets: MarketInterface[],
	logos: Array<string | null>,
	slogans: Array<string | null>,
	monthlyPrints: number,
	clientAPIEnabled: boolean,
	clientAPIUrl: string | null,
	enableSalestagImageAPI: boolean,
}

export const CompanyProvider: React.FC<CompanyProviderInterface> = ({ children }) => {
	const [companyID, setCompanyID] = useState<EntityID>(null);
	const [company, setCompany] = useState<CompanyInterface | null>(null);
	const [market, setMarket] = useState<MarketInterface | null>(null);
	const [useProductImage, setUseProductImage] = useState(false);
	const [useTextClub, setUseTextClub] = useState(false);

	// ID alvo para consultas
	const [targetCompanyID, setTargetCompanyID] = useState<EntityID>(null);

	const { member } = useMember();

	const fetchCompany = async () => {
		if (!companyID) return;
		const api = new Api('company', 'gf');
		const request = api.request(member, { companyID });
		const response = await api.post(request);
		if (response.success) {
			const { company } = response.data;
			const parsedCompany: CompanyInterface = {
				id: company.ID,
				name: company.Name,
				licenseEnabled: Boolean(company.LicenseEnabled),
				licenseExpiration: company.LicenseExpiration,
				licenseExpirationRemainingLabel: remainingTime(company.LicenseExpiration),
				isLicenseValid: Boolean(company.isLicenseValid),
				totalMarkets: company.TotalMarkets || 0,
				totalMembers: company.TotalMembers || 0,
				markets: parseMarkets(company.Markets),
				logos: parseLogos(Object.values(company.Logos)),
				slogans: Object.values(company.Slogans),
				monthlyPrints: company.MonthlyPrints,
				clientAPIUrl: company.ClientAPIUrl,
				clientAPIEnabled: Boolean(company.EnableClientAPI),
				enableSalestagImageAPI: Boolean(company.EnableSalestagImageAPI)
			}
			setUseTextClub(company.UseTextClub);
			setUseProductImage(company.UseProductImage);
			setCompany(parsedCompany);
			if (parsedCompany.markets?.length > 0) {
				parsedCompany.markets.forEach((currentMarket) => {
					if (currentMarket.id === member.marketID) setMarket(currentMarket);
				});
			}
		}
	}

	const saveToggleUseProductImage = async (toggle: boolean) => {
		const api = new Api('company', 'epi');
		const request = api.request(member, { companyID, useProductImage: toggle });
		const { success } = await api.post(request);
		success && setUseProductImage(toggle);
	}

	const saveToggleUseTextClub = async (toggle: boolean) => {
		const api = new Api('company', 'etc');
		const request = api.request(member, { companyID, useTextClub: toggle });
		const { success } = await api.post(request);
		success && setUseTextClub(toggle);
	}

	const resetContext = () => {
		setCompanyID(null);
		setCompany(null);
	}

	// Encarrega de manter os dados atualizados.
	useEffect(() => {
		if (member.isLogged()) {
			if (companyID) fetchCompany();
			else if (!companyID && member.companyID) setCompanyID(member.companyID);
		}
		else {
			resetContext();
		}
	}, [member, companyID]);

	return (
		<CompanyContext.Provider
			value={{
				fetchCompany,
				companyID,
				setCompanyID,
				company,
				market,
				targetCompanyID,
				setTargetCompanyID,

				useProductImage,
				saveToggleUseProductImage,

				useTextClub,
				saveToggleUseTextClub
			}}
		>
			{children}
		</CompanyContext.Provider>
	);
};

export const useCompany = (): CompanyContextData => {
	const context = useContext(CompanyContext);

	if (!context) {
		throw new Error(
			"useCompany must be used within a CompanyProvider"
		);
	}

	return context;
};

function parseMarkets(data: any[]): MarketInterface[] {
	return data.map(market => ({
		id: market.ID,
		storeID: market.StoreID,
		name: market.Name,
		totalMembers: market.TotalMembers || 0,
		monthlyPrints: market.MonthlyPrints
	}));
}

function parseLogos(data: Array<string | null>): Array<string | null> {
	if (!process.env.NEXT_PUBLIC_HOST_URL) {
		throw new Error("NEXT_PUBLIC_HOST_URL must be set.")
	}
	return data.map(logoUrl => (logoUrl ? `${process.env.NEXT_PUBLIC_HOST_URL}${logoUrl}?t=${now()}` : null));
}

function remainingTime(date: string): string | null {
	const now = DateTime.local();
	const targetDate = DateTime.fromISO(date);
	const difference = Interval.fromDateTimes(now, targetDate).toDuration(['years', 'months', 'days']);

	const years = Math.floor(difference.years || 0);
	const months = Math.floor(difference.months || 0) % 12;
	const days = Math.floor(difference.days || 0) % 30;

	if (years > 0)
		return `${years} ${years > 1 ? 'anos' : 'ano'}`;

	if (months > 0)
		return `${months} ${months > 1 ? 'meses' : 'mês'}`;

	if (days > 0)
		return `${days} ${days > 1 ? 'dias' : 'dia'}`;
	else if (difference.days > 0)
		return `1 dia`;

	return null;
}
