import { ProductListItem, SilverProduct } from "@/types/products";
import PriceHelper from "./PriceHelper";

export default class ProductHelper {
	static parseProduct(item: SilverProduct): ProductListItem {
		return {
			id: item.ID,
			ean: item.Ean,
			innerCode: item.Code,
			description: item.Description,
			packType: item.PackType,
			packQuantity: item.PackQuantity,
			preview: item.ImageURL,
			sPrice: PriceHelper.isPriceValid(item.SPrice) ? item.SPrice : '-',
			cPrice: PriceHelper.isPriceValid(item.CPrice) ? item.CPrice : '-',
			oPrice: PriceHelper.isPriceValid(item.OPrice) ? item.OPrice : '-',
			ePrice: PriceHelper.isPriceValid(item.EPrice) ? item.EPrice : '-',
			extraParameter_1: item.ExtraParameter_1,
			extraParameter_2: item.ExtraParameter_2,
			extraParameter_3: item.ExtraParameter_3,
			extraParameter_4: item.ExtraParameter_4,
			extraParameter_5: item.ExtraParameter_5,
			extraParameter_6: item.ExtraParameter_6,
			extraParameter_7: item.ExtraParameter_7,
			extraParameter_8: item.ExtraParameter_8,
			extraParameter_9: item.ExtraParameter_9,
			extraParameter_10: item.ExtraParameter_10,
			initDate: item.InitDate,
			endDate: item.EndDate
		}
	}
}
