import React, { useEffect, useState } from 'react';
import { EditorState, ElementData, GroupData, ProductState } from "@/types/canvas";
import { useCanvas, useCanvasDispatch } from "@/contexts/editor/CanvasContext";
import Api from '@/api/Api';
import { useMember } from '@/contexts/member/MemberContext';
import Notify from '@/helpers/Notify';
import { useModels, ItemListType } from '@/contexts/models/ModelsContext';
import ImageHelper from '@/helpers/ImageHelper';
import { CanvasState } from '@/types/canvas';
import { useModals } from '@/contexts/modals/ModalsContext';
import { useProducts } from '@/contexts/products/ProductsContext';
import { __BANNER_TYPES__ } from '@/types/constants';
import { UnselectedStarIcon, StarIcon } from '@/components/svg/StarIcon';
import CloneIcon from '@/components/svg/CloneIcon';
import TrashIcon from '@/components/svg/TrashIcon';
import { CheckedBorderWithEffectIcon } from '@/components/svg/CheckedIcon';
import CanvasLoadingModal from '@/components/modals/canvas/CanvasLoadingModal';

interface BannerCardInterface {
    item: ItemListType,
    type: "open" | "reload",
    enableActions?: boolean,
    width?: number,
    className?: string
}

type PartialEditorState = Partial<EditorState>;

type PartialProductState = Partial<ProductState>;

type PartialCanvasState = {
    templateId?: string | null;
    templateName?: string | null;
    campaignId?: string | null;
    campaignName?: string | null;
    elements?: ElementData[];
    groups?: GroupData[];
    editor?: PartialEditorState;
    products?: PartialProductState;
};

const BannerCard: React.FC<BannerCardInterface> = ({ item, type, enableActions = false, width = 220, className = '' }) => {
    const { id, name, preview, privacy, companyID, companyName } = item;

    const { member, refetchMember } = useMember();

    const isFav = member.favorites.includes(id);

    const {
        isLoadingModels,
        setIsLoadingModels,
        setRefreshList,
        showOfferSelector,
        setTargetModel
    } = useModels();

    const [isActionsOpen, setIsActionsOpen] = useState(false);

    const handleToggleActions = () => {
        setIsActionsOpen(!isActionsOpen);
    };

    const onMouseLeave = () => setIsActionsOpen(false);

    const previewURL = ImageHelper.createServerURL(preview);

    const { loadStateData, reloadTemplate } = useCanvas();
    const { stateDispatch } = useCanvasDispatch();

    const { setShowCanvasModal } = useModals();

    const { setIsBannerRulerModalVisible, setBannerToFound } = useProducts();

    const api = new Api('templates');

    const [isLoading, setIsLoading] = useState(false);

    const onClickOpenModel = async () => {
        stateDispatch({ type: "setTemplateId", templateId: id });
        stateDispatch({ type: "setTemplateName", templateName: name });
        setShowCanvasModal(true)
        setIsLoading(true);
        const isReloaded = await reloadTemplate(id);
        if (!isReloaded) {
            Notify.Error("Erro ao carregar o Template!");
            setIsLoading(false);
        }

        isReloaded && setIsLoading(false);
        // showOfferSelector();
    }

    const __onClickOpenModel = async () => {
        setTargetModel(item.id);
        showOfferSelector();
    }

    const onClickReloadModel = async () => {
        const request = api.request(member, { templateID: id });
        const response = await api.post(request, 'g');
        if (response.success) {
            const canvasState = convertResponseToCanvasState(response);
            loadStateData(canvasState as CanvasState);
        }
        else Notify.Error("Houve um erro com este template!");
        setIsLoading(false);
    }

    useEffect(() => {
        return () => setIsLoading(false);
    }, []);

    const handleApiRequest = async (apiEndpoint: string, successMessage: string, errorMessage: string, onSuccess?: () => void) => {
        const request = api.request(member, { templateId: id });
        setIsLoading(true);
        setIsLoadingModels(true);
        await api.post(request, apiEndpoint)
            .then((response) => {
                if (response.success && response.data.result) {
                    Notify.Success(successMessage);
                    setRefreshList(true);
                    if (onSuccess) onSuccess();
                }
                else {
                    Notify.Error(errorMessage);
                }
            })
            .finally(() => {
                setIsLoading(false);
                if (isLoadingModels) setIsLoadingModels(false);
            });
    };

    const handleDuplicate = async () => {
        await handleApiRequest('du', "Template duplicado!", "Não foi possível duplicar o modelo.");
    };

    const handleDelete = async () => {
        await handleApiRequest('d', "Template removido", "Não foi possível remover o modelo.");
    };

    const handleFavorite = async () => {
        const endpoint = isFav ? 'uf' : 'f';
        const successMessage = isFav ? 'Template removido dos favoritos.' : 'Template favoritado';
        const errorMessage = isFav ? 'Não foi possível desfavoritar o modelo.' : 'Não foi possível favoritar o modelo.';
        await handleApiRequest(endpoint, successMessage, errorMessage, () => refetchMember({}));
    }

    const handleRuleClick = () => {
        setBannerToFound(item);
        setIsBannerRulerModalVisible(true);
    }

    const showDropdown = member.admin() || (member.can('create_banner') && member.companyID === companyID);

    const classWidth = `w-[${width}px]`;

    return (
        <div onMouseLeave={onMouseLeave} className="relative bg-zinc-100 rounded-lg min-h-[14rem] border border-zinc-300 h-full overflow-hidden">
            <CanvasLoadingModal visible={isLoading} />
            <div className={`group ${classWidth} h-full min-h-fit max-w-[12rem] ${className} rounded-lg`}>
                <div className="flex justify-center items-center h-full ">
                    <img
                        className="object-cover rounded"
                        src={previewURL || "/images/no-img.png"}
                        alt={`Cartaz ${name}`} width={200} height={200}
                    />
                </div>

                {
                    enableActions && showDropdown && (
                        <div className="absolute top-0 right-0 mt-2 mr-2 flex flex-col">
                            <button
                                className="px-2 py-1 max-h-fit z-10 bg-orange-500 text-white font-semibold text-sm rounded shadow-lg group-hover:visible invisible ml-auto"
                                onClick={handleToggleActions}
                            >
                                Ações
                            </button>
                            {
                                isActionsOpen && (
                                    <div
                                        className={`mt-2 bg-white border z-10 border-gray-200 rounded shadow-lg text-gray-700 dark:bg-gray-800}`}
                                    >
                                        <button
                                            className={`flex items-center justify-start gap-2 w-full px-4 py-2 text-left  ${isFav && 'text-red-500'} hover:bg-zinc-100 dark:hover:bg-gray-700`}
                                            onClick={handleFavorite}
                                        >
                                            {
                                                (isFav) ? (
                                                    <UnselectedStarIcon color='fill-rose-500' />
                                                ) : (
                                                    <StarIcon color='fill-amber-500' />
                                                )
                                            }
                                            {isFav ? 'Desfavoritar' : 'Favoritar'}
                                        </button>
                                        {
                                            (member.admin() || member.can('create_banner')) && (member.companyID === companyID) && (
                                                <>
                                                    <button
                                                        className="flex items-center justify-start gap-2 fill-zinc-500 w-full px-4 py-2 text-left hover:bg-zinc-100 dark:hover:bg-gray-700"
                                                        onClick={handleDuplicate}
                                                    >
                                                        <CloneIcon color='fill-blue-500' />
                                                        Duplicar
                                                    </button>
                                                    <React.Fragment>
                                                        <button
                                                            className="flex items-center justify-start gap-2 fill-green-500 w-full px-4 py-2 text-left hover:bg-zinc-100 dark:hover:bg-gray-700"
                                                            onClick={handleRuleClick}
                                                        >
                                                            <CheckedBorderWithEffectIcon />
                                                            Ativação
                                                        </button>
                                                        <button
                                                            className="flex items-center justify-start gap-2 fill-rose-500 text-rose-500 w-full px-4 py-2 text-left hover:bg-zinc-200 dark:hover:bg-gray-700"
                                                            onClick={handleDelete}
                                                        >
                                                            <TrashIcon className='w-4 h-4' />
                                                            Remover
                                                        </button>
                                                    </React.Fragment>
                                                </>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:opacity-100 opacity-0 transition-opacity duration-500 ease-in-out flex flex-col justify-end p-3">
                    <div className='mb-2 '>
                        <div className='flex flex-col'>
                            <span className="text-xs text-white">
                                {privacy === 'public' ? 'Público' : `Exclusivo ${companyName}`}
                            </span>
                        </div>
                        <h5 className="text-white text-lg">{name}</h5>
                    </div>
                    {/*{*/}
                    {/* member.can('show_open_banner_button') && (*/}
                    <button
                        onClick={type === "open" ? onClickOpenModel : onClickReloadModel}
                        disabled={isLoading}
                        className={`${isLoading && 'cursor-not-allowed'
                            } h-10 inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-center rounded-lg text-white duration-[400ms] bg-gradient-to-r to-blue-400 via-teal-500 from-blue-500 bg-size-200 bg-pos-0 hover:bg-pos-100  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}
                    >
                        <span className='w-full'>Abrir Modelo</span>
                    </button>
                    {/*)*/}
                    {/*}*/}
                </div>
            </div>
        </div>
    );
};

export default BannerCard;

export const convertResponseToCanvasState = (response: any): PartialCanvasState => {
    const {
        Hash,
        Elements,
        Groups,
        Name,
        Orientation,
        Products,
        CampaignID,
        CampaignName
    } = response.data.template;

    const canvasState: PartialCanvasState = {
        templateId: Hash || null,
        templateName: Name || null,
        campaignId: CampaignID || null,
        campaignName: CampaignName || null,
        editor: {
            orientation: Orientation || "vertical",
        },
        elements: Elements,
        groups: Groups,
        products: {
            ids: Products || [],
            selected: null,
        }
    };

    return canvasState;
};
