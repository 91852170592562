import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ReactChildren } from "@/types";
import Api from "@/api/Api";
import ColorHelper from "@/helpers/ColorHelper";
import { useMember } from "../member/MemberContext";
import { LineChartData } from "@/components/charts/LineChart";
import { useQueue } from "../queue/QueueContext";
import { PieChartData } from "@/components/charts/PieChart";
import TextHelper from "@/helpers/TextHelper";
import { __DEFAULT_MOST_USED_FORMATS_DATA__ } from "./data";
import { Orientation } from "@/types/canvas";
import { TemplateHanking, FavoriteTemplate } from "@/types/dashboard";

interface DashboardProviderInterface {
    children: ReactChildren
}

interface DashboardContextData {
	refreshMonthlyView: (onFinish?: Function) => void,
	monthlyViewData: LineChartData[],
	isFetchingMonthlyView: boolean,

	mostUsedFormatsData: PieChartData[],
	isFetchingMostUsedFormats: boolean,

	templateHankingList: TemplateHanking[],
	isFetchingTemplateHanking: boolean,

	favoriteTemplateList: FavoriteTemplate[],
	isFetchingFavoriteTemplateList: boolean,

	isFetchingQueue: boolean,
	refreshQueue: () => void,

	refreshDashboard: (onFinish?: Function) => void,
}

const DashboardContext = createContext<DashboardContextData>(
    {} as DashboardContextData
);

export const DashboardProvider: React.FC<DashboardProviderInterface> = ({ children }) => {
	// Insights endpoint.
	const api = new Api('insights');

	const { member } = useMember();
	const { fetchQueueItems } = useQueue();

	const firstBoot = useRef<boolean>(false);

	// MonthlyView
	const [monthlyViewData, setMonthlyViewData] = useState<LineChartData[]>([]);
	const [isFetchingMonthlyView, setIsFetchingMonthlyView] = useState<boolean>(false);

	// Most used formats
	const [mostUsedFormatsData, setMostUsedFormatsData] = useState<PieChartData[]>(__DEFAULT_MOST_USED_FORMATS_DATA__);
	const [isFetchingMostUsedFormats, setIsFetchingMostUsedFormats] = useState<boolean>(false);

	// Template hanking
	const [templateHankingList, setTemplateHankingList] = useState<TemplateHanking[]>([]);
	const [isFetchingTemplateHanking, setIsFetchingTemplateHanking] = useState<boolean>(false);

	// Favorite templates
	const [favoriteTemplateList, setFavoriteTemplateList] = useState<FavoriteTemplate[]>([]);
	const [isFetchingFavoriteTemplateList, setIsFetchingFavoriteTemplateList] = useState<boolean>(false);

	// Queue counter
	const [isFetchingQueue, setIsFetchingQueue] = useState<boolean>(false);

	const refreshMonthlyView = async (onFinish: Function | undefined = undefined) => {
		// Insights requests
		const request = api.request(member, { companyID: member.companyID });
		setIsFetchingMonthlyView(true);
		const response = await api.post(request, 'mo');
		if (response.success)
		{
			const newData = [];
			const { marketCounts } = response.data;

			// Iterar sobre todos os mercados
			for (let market in marketCounts)
			{
				// Cada market é um objeto com label e count
				const { label, count } = marketCounts[market];

				// Iterar sobre o objeto count para criar os pontos
				const points = Object.entries(count).map(([x, y]) => ({ x: parseInt(x as string), y: y as number }));
				const color = ColorHelper.randomColor();
				newData.push({ label, points, color });
			}
			setMonthlyViewData(newData);
		}
		setIsFetchingMonthlyView(false);

		if (onFinish && typeof onFinish === 'function') onFinish();
	}

	const refreshQueue = async () => {
		setIsFetchingQueue(true);
		fetchQueueItems(() => setIsFetchingQueue(false));
	}

	const refreshMostUsedFormats = async () => {
		const request = api.useHash(member);

		setIsFetchingMostUsedFormats(true);

		const response = await api.post(request, 'muf');
		if (response.success)
		{
			const { formatsCount } = response.data;
			const data = [];
			for (const key in formatsCount)
			{
				const value = formatsCount[key];
				data.push({ label: key as Orientation, value: value });
			}
			setMostUsedFormatsData(convertPrintsToAngles(data));
		}
		setIsFetchingMostUsedFormats(false);
	}

	const refreshTemplateHanking = async () => {
		const request = api.useHash(member);
		
		setIsFetchingTemplateHanking(true);
		const response = await api.post(request, 'mut');
		if (response.success)
		{
			const { queueList } = response.data;
			const list = [];
			for (const key in queueList)
			{
				const item = queueList[key] as TemplateHanking;
				list.push(item);
			}
			setTemplateHankingList(list);
		}
		setIsFetchingTemplateHanking(false);
	}

	const refreshFavoriteTemplate = async () => {
		const templateAPI = new Api('templates');
		const request = templateAPI.useHash(member);
		
		setIsFetchingFavoriteTemplateList(true);
		const response = await templateAPI.post(request, 'gf');
		
		if (response.success)
		{
			const { favorites } = response.data;
			const { Templates: templates } = favorites;

			if (templates)
			{
				const list: FavoriteTemplate[] = [];
				templates.forEach((template: any) => {
					list.push({ hash: template.Hash, name: template.Name });
				});
				setFavoriteTemplateList(list);
			}
		}
		setIsFetchingFavoriteTemplateList(false);
	}

	// ----------------------------------------------------------------

	const refreshDashboard = (onFinish: Function | undefined = undefined) => {
		refreshMonthlyView(onFinish);
		refreshQueue();
		refreshMostUsedFormats();
		refreshTemplateHanking();
		refreshFavoriteTemplate();
	}

	useEffect(() => {
		if (member.isLogged() && !firstBoot.current)
		{
			firstBoot.current = true;
			refreshDashboard();
		}
	}, [member]);

    return (
        <DashboardContext.Provider
            value={{
				refreshMonthlyView,
				monthlyViewData,
				isFetchingMonthlyView,

				mostUsedFormatsData,
				isFetchingMostUsedFormats,

				templateHankingList,
				isFetchingTemplateHanking,

				favoriteTemplateList,
				isFetchingFavoriteTemplateList,

				isFetchingQueue,
				refreshQueue,

				refreshDashboard
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

export const useDashboard = (): DashboardContextData => {
    const context = useContext(DashboardContext);

    if (!context) {
        throw new Error(
            "useDashboard must be used within a DashboardProvider"
        );
    }

    return context;
};

function convertPrintsToAngles(printData: { label: Orientation, value: number }[]): PieChartData[]
{
	// Calcular a soma total de impressões
	const totalPrints = printData.reduce((acc, cur) => acc + cur.value, 0);

	// Mapear os dados para a nova forma
	const data = printData.map(store => {
		const proportion = store.value / totalPrints; // Proporção em relação ao total
		const angle = proportion * 360; // Conversão para ângulo
		return {
			label: TextHelper.getOrientationLabel(store.label),
			angle: angle
		};
	});

	return data;
}
