import { createContext, useContext } from "react";
import { ReactChildren } from "@/types";
import { ApXUNType, LeveXPagueYType, XOffType } from "../editor/DynamicPromoContext";
import { useCanvas } from "../editor/CanvasContext";
import { ProductListItem } from "@/types/products";
import { PriceType } from "@/types/canvas";

interface PricingProviderInterface {
    children: ReactChildren
}

interface PricingContextData {
    handlePriceByTextType: (
        product: ProductListItem,
        ap_x_UN: ApXUNType,
        leveX_pagueY: LeveXPagueYType,
        xOff: XOffType,
        calculate?: boolean
    ) => string[] | undefined | Error;
    priceTarget: (priceType: PriceType, product: ProductListItem) => string | null;
}

const PricingContext = createContext<PricingContextData>(
    {} as PricingContextData
);

export const PricingProvider: React.FC<PricingProviderInterface> = ({ children }) => {
    const { state } = useCanvas();

    const handlePriceByTextType = (
        product: ProductListItem,
        ap_x_UN: ApXUNType,
        leveX_pagueY: LeveXPagueYType,
        xOff: XOffType,
        calculate?: boolean
    ): string[] | undefined | Error => {
        const templateType = state.comboType;
        const currentPriceType = checkPriceInfo(product);
        const price = priceTarget(currentPriceType, product);

        if (
            templateType === "leve_x_pague_y" && (leveX_pagueY.leve <= 0 || leveX_pagueY.pague <= 0)
        ) return new Error("Um ou mais campos não foram preenchidos!");

        if (templateType === "leve_x_pague_y" && price && calculate) {
            const productValue = parseFloat(price);
            const levePrice = productValue * leveX_pagueY.leve;
            const discountPay = levePrice - ((leveX_pagueY.leve - leveX_pagueY.pague) * productValue);
            const discountValue = discountPay / leveX_pagueY.leve;
            return discountValue.toFixed(2).toString().split(".");
        }

        if (templateType === "ap_x_un" && price) {
            return ap_x_UN.price.split(",");
        }

        if (templateType === "xOff" && price) {
            const discountValue = parseFloat(price) * (xOff.xOff / 100);
            return discountValue.toFixed(2).split(".");
        }

        return;
    }

    function checkPriceInfo(product: ProductListItem): PriceType {
        let productPrice: PriceType = "simple";

        if (![null, 0, "", "0.00"].includes(product.sPrice)) {
            productPrice = "simple";
        }

        if (![null, 0, "", "0.00"].includes(product.oPrice)) {
            productPrice = "offer";
        }

        if (![null, 0, "", "0.00"].includes(product.cPrice)) {
            productPrice = "club";
        }

        return productPrice;
    }

    const priceTarget = (priceType: PriceType, product: ProductListItem) => {
        switch (priceType) {
            case "simple":
                return product.sPrice;
            case "offer":
                return product.oPrice;
            case "club":
                return product.cPrice;
            case "extra":
                return product.ePrice;
            default:
                return product.sPrice;
        }
    }

    return (
        <PricingContext.Provider
            value={{
                handlePriceByTextType,
                priceTarget
            }}
        >
            {children}
        </PricingContext.Provider>
    );
};

export const usePricing = (): PricingContextData => {
    const context = useContext(PricingContext);

    if (!context) {
        throw new Error(
            "usePricing must be used within a PricingProvider"
        );
    }

    return context;
};
